<template>
  <div class="addUser-position-information">
    <el-form :disabled="formConfig.btnCode === 'view'" :model="infoObj" label-position="right" ref="infoObjFormRef" label-width="100px">
      <div v-if="userCanRelationManyCustomer ==='Y'">
        <el-row v-for="(item,index) in infoObj.customerList" :key="index">
          <el-col :span="24">
            <div class="arrItemTitle">
              <div class="arrItemTitle-left">
                <p>
                  关联客户{{index + 1}}
                </p>
                <div v-if="!(formConfig.btnCode === 'view')">
                  <el-button type="text" v-if="index === 0" @click="addArrItem(infoObj.userType,infoObj.customerList)">
                    <i style="font-size: 20px" class="el-icon-circle-plus-outline"></i>
                  </el-button>
                  <el-button type="text"
                             @click="delArrItem(infoObj.userType,infoObj.customerList,index)">
                    <i style="font-size: 20px" class="el-icon-delete"></i>
                  </el-button>
                </div>

              </div>
              <div class="arrItemTitle-right">

              </div>
            </div>
          </el-col>

          <el-col :span="8">
            <el-form-item
              :prop="'customerList.' + index + '.customerCode'"
              :rules="{required: true, message: '关联客户不能为空', trigger: 'change'}"
              label="关联客户">
              <el-select remote :remote-method="(name)=>{getItemCustomerCodeOptionArr(name,item.customerCode,index)}"
                         @focus="getItemCustomerCodeOptionArr('',item.customerCode,index)"
                          filterable clearable v-model="item.customerCode">
                <el-option v-for="(item2,index2) in item.itemCustomerCodeOptionArr" :key="index2" :label="item2.label"
                           :value="item2.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

      </div>

    </el-form>
  </div>
</template>

<script>
import request from '@/found/utils/request';
// import Password from '@/found/components/form/components/form_password.vue';

export default {
  // extends: Form,
  name: 'addUser',
  props: {
    id: String,
    row: Object,
    formConfig: {
      type: Object,
      default: () => ({}),
    },
    customerList: Array,

  },
  components: {
    // Password,
  },
  data() {
    return {
      userCanRelationManyCustomer: 'Y', // Y/N 客户用户是否可以关联多个客户，Y表示可以关联多个，N表示最多只能关联一个
      passwordDefault: '******',
      infoObj: {
        id: '',
        userType: '',
        fullName: '',
        gender: '',
        userPhone: '',
        userName: '',
        userPassword: '',
        email: '',
        startTime: '',
        endTime: '',
        customerCode: '',
        roleCode: '',
        customerList: [
          {
            customerCode: '',
            itemCustomerCodeOptionArr: [],
            roleCode: '',
            itemRoleCodeOptionArr: [],
          },
        ],

      },
      showPassword: true,
      findCustomerSelectListArr: [], // 关联客户的下拉选项 数组
      roleSelectListArr: [], // 角色的下拉选项 数组
      // 操作类型
      operationTypeOptionArr: [],
      // 是否主职位
      primaryFlagOptionArr: [],
      positionCanUsedToRelateUserListArr: [], // 职位的下拉选项 数组
      genderOptionArr: [], // 用户类型下拉选项
    };
  },
  watch: {
    'infoObj.customerList': {
      deep: true,
      immediate: true,
      handler(customerList) {
        this.$emit('input', customerList);
        this.$emit('change', customerList);
      },
    },
    customerList: {
      deep: true,
      immediate: true,
      handler(v) {
        // console.log(v);
        if (v && v.length > 0) {
          const customerList = Array.isArray(v) ? v.map((item) => ({
            customerCode: item.customerCode,
            itemCustomerCodeOptionArr: [],
          })) : [];
          customerList.forEach((item, index) => {
            this.getItemCustomerCodeOptionArr(this.userName, item.customerCode, index);
          });
          this.infoObj.customerList = customerList;
        }
      },
    },

  },
  created() {
    this.getCustomerUserControl();
  },
  mounted() {},
  methods: {
    // 密码框change事件
    passwordChange(e) {
      if (!e) {
        this.showPassword = false;
        setTimeout(() => {
          this.showPassword = true;
          this.$nextTick(() => {
            this.$refs.password.focus();
          });
        }, 1);
      }
    },
    // 获取客户用户新增编辑页面控制变量
    getCustomerUserControl() {
      request.get('/mdm/mdmCustomerUserController/getCustomerUserControl').then((res) => {
        if (res.success) {
          // this.userCanRelationManyCustomer = res.result.userCanRelationManyCustomer;
          if (this.formConfig && this.formConfig.id) {
            this.getUserInfoObjDetails(this.formConfig.id);
          }
        }
      });
    },

    //  获取多客户关联  每一项 下拉选项

    getItemCustomerCodeOptionArr(name, selectedCode, index) {
      console.log(28333333, name, selectedCode, index);
      request.post('/mdm/mdmCustomerMsgController/select', {
        userName: this.infoObj.userName,
        enableStatus: '009',
        unionName: name,
        selectedCode,
      }, { headers: { functionCode: 'select-customer' } }).then((res) => {
        if (res.success) {
          this.$nextTick(() => {
            this.infoObj.customerList[index].itemCustomerCodeOptionArr = res.result.map((a) => ({
              label: a.unionName,
              value: a.customerCode,
            }));
            return res.result.map((a) => ({ label: a.unionName, value: a.customerCode }));
          });
        } else {
          return [];
        }
      });
    },
    getItemRoleCodeOptionArr(name, selectedCode, index) {
      request.post('/mdm/mdmRoleController/roleSelectList', {
        enableStatus: '009',
        roleName: name,
        selectedCode,
      }, { headers: { functionCode: 'select-permissions-role' } }).then((res) => {
        if (res.success) {
          this.$nextTick(() => {
            this.infoObj.customerList[index].itemRoleCodeOptionArr = res.result.map((a) => ({
              label: a.roleName,
              value: a.roleCode,
            }));
            return res.result.map((a) => ({ label: a.roleName, value: a.roleCode }));
          });
        } else {
          return [];
        }
      });
    },

    // 根据 id  获取 详情
    async getUserInfoObjDetails(id = '') {
      request.get('/mdm/mdmCustomerUserController/detail', { id }).then((res) => {
        if (res.success) {
          this.infoObj = res.result;
          this.infoObj.userPassword = this.passwordDefault;
          if (this.userCanRelationManyCustomer === 'N') {
            this.getFindCustomerSelectListArr('', this.infoObj.customerCode);
            this.getRoleSelectListArr('', this.infoObj.roleCode);
          } else {
            if (res.result.customerList.length === 0) {
              this.infoObj = {
                ...this.infoObj,
                customerList: [
                  {
                    customerCode: '',
                    itemCustomerCodeOptionArr: [],
                    roleCode: '',
                    itemRoleCodeOptionArr: [],
                  },
                ],
              };
            }

            this.infoObj = {
              ...this.infoObj,
              customerList: this.infoObj.customerList.map((a) => ({
                ...a,
                itemCustomerCodeOptionArr: [],
                itemRoleCodeOptionArr: [],
              })),
            };
            this.infoObj.customerList.forEach((a, index) => {
              this.getItemCustomerCodeOptionArr('', a.customerCode, index);
              this.getItemRoleCodeOptionArr('', a.roleCode, index);
            });
          }
        }
      });
    },

    //  一对一  关联客户改变  customerCodeSelectedChange

    customerCodeSelectedChange(code) {
      if (!this.infoObj.id) {
        this.infoObj.userName = code;
      }
      this.infoObj.fullName = this.findCustomerSelectListArr.find((a) => a.value === code).label;
    },
    // 获取关联客户下拉
    getFindCustomerSelectListArr(name, selectedCode) {
      request.post('/mdm/mdmCustomerMsgController/findCustomerNotRelateUserSelectList', {
        userName: this.infoObj.userName,
        enableStatus: '009',
        customerName: name,
        selectedCode,
      }, { headers: { functionCode: 'select-customer' } }).then((res) => {
        if (res.success) {
          this.findCustomerSelectListArr = res.result.map((a) => ({ label: a.customerName, value: a.customerCode }));
        }
      });
    },

    // 获取角色下拉
    getRoleSelectListArr(name, selectedCode) {
      request.post('/mdm/mdmRoleController/roleSelectList', {
        enableStatus: '009',
        roleName: name,
        selectedCode,
      }, { headers: { functionCode: 'select-permissions-role' } }).then((res) => {
        if (res.success) {
          this.roleSelectListArr = res.result.map((a) => ({ label: a.roleName, value: a.roleCode }));
        }
      });
    },

    submitInfoObj() {
      let isRight = false;
      this.$refs.infoObjFormRef.validate((valid) => {
        if (valid) {
          isRight = valid;
        }
      });
      return isRight;
    },

    //  新增每一i想
    addArrItem(userType, arr) {
      arr.push(
        {
          customerCode: '',
          itemCustomerCodeOptionArr: [],
          roleCode: '',
          itemRoleCodeOptionArr: [],
        },
      );
    },
    // 删除每一项
    delArrItem(userType, arr, index) {
      if (arr.length <= 1) {
        this.$message.error('必须保留一条数据');
        return;
      }
      this.$confirm('确认删除？', '提示').then(() => {
        arr.splice(index, 1);
      });
    },

  },

};
</script>

<style scoped lang="less">
  /deep/ .el-form-item__label {
    font-size: 12px !important;
  }

  /deep/ .el-button--small.is-circle {
    padding: 3px;
  }

  .addUser-position-information {

    .footer-btn {
      position: absolute;
      bottom: 0;
      right: 10px;

      button {
        width: 100px;
      }
    }

    .arrItemTitle {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;

      .arrItemTitle-left {
        display: flex;
        align-items: center;
        justify-content: center;

        p {
          margin: 0 20px 0 0;
          font-size: 16px;
          height: 20px;
          line-height: 20px;
          font-weight: 600;
        }

      }

      .arrItemTitle-right {

      }

    }

  }

</style>
