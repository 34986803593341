<script>
import TablePage from '../../../../../../components/table_page';

export default {
  extends: TablePage,
  props: {
    propsObj: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      // requestUrl: '/mdm/mdmUserController/pageList',
      // configs,
      params: {
        excludeUserNameList: [this.propsObj.userName],
        enableStatus: '009',
      },
      configs: {
        formConfig: {
          titleWidth: 100,
          titleAlign: 'right',
          item: [],
        },
        isCalculateHeight: false,
        toolBarConfig: [
        ],
        columns: [],
        tableConfig: {},
      },
      isCalculateHeight: false,
    };
  },
  created() {
    this.getConfigList('mdm_customer_user_page_list', true, true);
  },
  mounted() {
  },
  methods: {
    // 重写 让其不被覆盖
    setButton() {

    },
    // 重新 覆盖 按钮事件

    buttonClick({ val }) {
      if (val.buttonCode === 'submitReplace') {
        if (this.selectRow.length > 0) {
          this.$emit('submit', this.selectRow);
        } else {
          this.$message('请勾选');
        }
      }

      if (val.buttonCode === 'cancel') {
        this.$emit('onClose');
      }
    },
  },

};
</script>

<style scoped lang="less">
  .associated_customer{
    /*height: 50vh;*/
  }

</style>
