<script>
import TablePage from '../../../../../../components/table_page';
import request from '../../../../../../utils/request';

export default {
  extends: TablePage,
  components: {
  },
  props: {
    userItemRowObj: {},
  },
  data() {
    return {
      activeTabPaneName: 'first',
      configs: {
        formConfig: {
          titleWidth: 100,
          titleAlign: 'right',
          item: [],
        },
        toolBarConfig: [
          {
            name: '添加关联',
            buttonType: '0',
            buttonCode: 'addAssociated',
          },
        ],
        columns: [],
        tableConfig: {

        },
      },
      isCalculateHeight: false,
    };
  },
  created() {
    if (this.userItemRowObj.enableStatus !== '009') {
      this.configs.toolBarConfig = [];
    }
    this.params = { ...this.params, userName: this.userItemRowObj.userName };
    this.getConfigList('mdm_terminal_not_rel_any_customer_user_list', true, true);
  },
  mounted() {

  },
  methods: {
    // 重写 让其不被覆盖
    setButton() {

    },
    // 重新 覆盖 按钮事件

    buttonClick({ val }) {
      if (val.buttonCode === 'addAssociated') {
        if (this.selectRow.length > 0) {
          // this.$confirm('确认提交吗？', '提示').then(() => {
          const params = {
            terminalCodeList: this.selectRow.map((a) => a.terminalCode),
            userName: this.userItemRowObj.userName,
          };
          request.post('/mdm/mdmCustomerUserController/bindCustomerUserRelTerminal', params).then((res) => {
            if (res.success) {
              this.selectRow = [];
              this.getList();
              this.$emit('refresh');
              this.$message({
                message: res.message,
                type: 'success',
              });
            }
          });
          // });
        } else {
          this.$message('请勾选');
        }
      }
    },

  },

};
</script>

<style scoped lang="less">
  .associated_customer{
    /*height: 50vh;*/
  }

</style>
