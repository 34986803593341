<script>
import Form, { formCreate } from '../../../../../../components/form';
import request from '../../../../../../utils/request';

import customerList from './components/customerList/customerList.vue';
import password from '../../../../../../components/form/components/form_password.vue';

formCreate.component('customerList', customerList);
formCreate.component('password', password);

export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      userCanRelationManyCustomer: '', // Y/N 客户用户是否可以关联多个客户，Y表示可以关联多个，N表示最多只能关联一个
      passwordDefault: '******',
      infoObj: {
        id: '',
        userType: '',
        fullName: '',
        gender: '',
        userPhone: '',
        userName: '',
        userPassword: '',
        email: '',
        startTime: '',
        endTime: '',
        customerCode: '',
        roleCodeList: [],
        customerList: [],
      },
      showPassword: true,
      findCustomerSelectListArr: [], // 关联客户的下拉选项 数组
      roleSelectListArr: [], // 角色的下拉选项 数组
      // 操作类型
      operationTypeOptionArr: [],
      // 是否主职位
      primaryFlagOptionArr: [],
      positionCanUsedToRelateUserListArr: [], // 职位的下拉选项 数组
      genderOptionArr: [], // 用户类型下拉选项
      formFunctionCode: 'user_client_management_add_onetoone',
    };
  },
  methods: {
    setRule(v) {
      const item = v;
      if (item.field === 'endTime') {
        Object.assign(item, {
          ...this.props,
          props: {
            type: 'datetime',
            valueFormat: 'yyyy-MM-dd HH:mm:ss',
            defaultTime: '23:59:59',
            relation: {
              beginField: 'startTime',
              endField: 'endTime',
              gtNow: true,
            },
          },
        });
      }
      if (item.field === 'startTime') {
        Object.assign(item, {
          ...this.props,
          props: {
            type: 'datetime',
            valueFormat: 'yyyy-MM-dd HH:mm:ss',
            relation: {
              beginField: 'startTime',
              endField: 'endTime',
              gtNow: true,
            },
          },
        });
      }
      if (item.field === 'userPhone') {
        item.props = {
          ...item.props,
          maxLength: 11,
        };
        item.validate = [
          ...item.validate,
          { pattern: /^1[3456789]\d{9}$/, message: '请输入正确手机号', trigger: 'change' },
        ];
      }
      if (item.field === 'userPassword') {
        Object.assign(item, {
          ...this.props,
          props: {
            placeholder: '密码',
          },
        });
      }
      if (item.field === 'roleCodeList') {
        item.restful = '/mdm/mdmRoleController/roleSelectList';
        item.headers = { functionCode: 'select-permissions-role' };
        item.restfulParams = { enableStatus: '009' };
        item.optionsKey = { label: 'unionName', value: 'roleCode' };
        item.props = {
          ...item.props,
          filterable: true,
          remote: true,
          remoteParams: 'unionName',
        };
      }
      if (item.field === 'customerCode') {
        item.restful = '/mdm/mdmCustomerMsgController/select';
        item.headers = { functionCode: 'select-customer' };
        item.restfulParams = { enableStatus: '009', userName: this.infoObj.userName };
        item.optionsKey = { label: 'unionName', value: 'customerCode' };
        item.props = {
          ...item.props,
          filterable: true,
          remote: true,
          remoteParams: 'unionName',
        };
      }
      return item;
    },
    formComplete() {
      const userName = this.getRule('userName');
      console.log(this.getRule('userType'));
      // 处理用户类型
      const userType = this.getRule('userType');
      let userTypeOption = [];
      // this.hiddenFields(true, 'customerList');

      this.hiddenFields(true, 'customerCode');
      this.display(false, 'customerList');
      const customerCode = this.getRule('customerCode');
      // if (customerCode) {
      //   customerCode.on = {
      //     ...customerCode.on,
      //     change: (code) => {
      //       if (!this.infoObj.id) {
      //         this.setValue({
      //           userName: code,
      //         });
      //       }
      //       this.setValue({
      //         fullName: customerCode.options.find((a) => a.value === code).customerName,
      //       });
      //     },
      //   };
      // }

      // 获取用户类型
      request.post('/mdm/mdmdictdata/list', { dictTypeCode: 'user_type' }).then((v) => {
        if (v.success) {
          userTypeOption = v.result.filter((item) => item.dictCode === 'c' || item.dictCode === 'customer_employee').map((itemc) => ({
            label: itemc.dictValue,
            value: itemc.dictCode,
          }));
          if (userTypeOption.length === 2) {
            userType.options = userTypeOption;
            userType.on = {
              ...userType.on,
              change: (e) => {
                if (e === 'c') {
                  this.getCustomerUserControl();
                  this.display(true, 'customerCode');
                  this.display(true, 'customerList');
                } else {
                  this.userCanRelationManyCustomer = '';
                  this.hiddenFields(false, 'customerCode');
                  this.hiddenFields(true, 'customerList');
                  this.display(true, 'customerCode');
                  this.display(true, 'customerList');
                }
              },
            };
          } else if (userTypeOption.length === 1) {
            this.setValue({
              userType: userTypeOption[0].value,
            });
            this.hiddenFields(true, 'userType');
            if (userTypeOption[0].value === 'c') {
              this.getCustomerUserControl();
              this.display(true, 'customerCode');
              this.display(true, 'customerList');
            } else {
              this.userCanRelationManyCustomer = '';
              this.hiddenFields(false, 'customerCode');
              this.hiddenFields(true, 'customerList');
              this.display(true, 'customerCode');
              this.display(true, 'customerList');
            }
          }
        }
      });

      // 编辑或查看
      if (this.formConfig.id) {
        this.disabled(true, 'userType');
        request.get('/mdm/mdmCustomerUserController/detail', { id: this.formConfig.id }).then((res2) => {
          if (res2.success) {
            const { result } = res2;

            // delete result.customerList;

            this.infoObj = {
              ...result,
              userPassword: this.passwordDefault,
            };
            const customerListCom = this.getRule('customerList');
            customerListCom.props = {
              ...customerListCom.props,
              formConfig: this.formConfig,
              customerList: res2.result.customerList,
            };

            if (this.infoObj.userType === 'c') {
              this.getCustomerUserControl();
              this.display(true, 'customerCode');
              this.display(true, 'customerList');
            } else {
              this.hiddenFields(true, 'customerList');
              this.hiddenFields(false, 'customerCode');
              this.display(true, 'customerCode');
              this.display(true, 'customerList');
            }
            this.setValue(this.infoObj);
          }
        });

        if (userName) {
          userName.props = {
            ...userName.props,
            disabled: true,
          };
        }
      }
    },
    // 密码框change事件
    passwordChange(e) {
      if (!e) {
        this.showPassword = false;
        setTimeout(() => {
          this.showPassword = true;
          this.$nextTick(() => {
            this.$refs.password.focus();
          });
        }, 1);
      }
    },
    // 获取客户用户新增编辑页面控制变量
    getCustomerUserControl() {
      request.get('/mdm/mdmCustomerUserController/getCustomerUserControl').then((res) => {
        if (res.success) {
          this.userCanRelationManyCustomer = res.result.userCanRelationManyCustomer;
          // this.getFromRule();
          // 存在 对客户信息信息赋值

          if (this.userCanRelationManyCustomer === 'Y') {
            this.hiddenFields(true, 'customerCode');
            this.hiddenFields(false, 'customerList');
            const customerListCom = this.getRule('customerList');
            if (customerListCom) {
              customerListCom.props = {
                ...customerListCom.props,
                formConfig: this.formConfig,
                customerList: this.infoObj.customerList,
              };
            }
          } else {
            this.hiddenFields(false, 'customerCode');
            this.hiddenFields(true, 'customerList');
          }
        }
      });
    },

    submit() {
      const formData = this.getFormData();
      if (this.userCanRelationManyCustomer === 'Y') {
        const isRight = this.fApi.method('customerList', 'submitInfoObj')();
        if (!isRight) {
          return false;
        }
      } else {
        formData.customerList = [];
      }

      if (formData) {
        this.infoObj = {
          ...this.infoObj,
          ...formData,
        };
        // 默认新增
        let url = '/mdm/mdmCustomerUserController/save';
        // 有id  就是编辑
        if (this.infoObj.id) {
          url = '/mdm/mdmCustomerUserController/update';
          this.infoObj.userPassword = this.infoObj.userPassword === this.passwordDefault ? '' : this.infoObj.userPassword;
        }
        const params = this.infoObj;
        params.customerList.forEach((v) => {
          const item = v;
          delete item.itemCustomerCodeOptionArr;
        });
        request.post(url, params).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: '操作成功',
            });
            this.$emit('onClose');
            this.$emit('onGetList');
          }
        });
      }
    },

  },
};
</script>
