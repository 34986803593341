<script>
import TablePage from '../../../../../../components/table_page';
import request from '../../../../../../utils/request';

import associatedCustomerSelectPosition from './look_subordinates_select_superior.vue';

export default {
  extends: TablePage,
  props: {
    propsObj: {},

  },
  components: {
    associatedCustomerSelectPosition,
  },

  data() {
    return {
      // requestUrl: '/mdm/mdmUserController/pageList',
      // configs,
      params: {
        userName: this.propsObj.userName,
        // userName: 'admin',
      },
      configs: {
        formConfig: {
          titleWidth: 100,
          titleAlign: 'right',
          item: [],
        },
        toolBarConfig: [
          {
            name: '选择上级',
            buttonType: '0',
            buttonCode: 'addAssociated',
          },
        ],
        columns: [],
        tableConfig: {

        },
      },
    };
  },
  created() {
    console.log(45555555555, this.propsObj);

    this.getConfigList('mdm_user_management_look_subordinates', true, true);
  },
  mounted() {

  },
  methods: {
    // 重写 让其不被覆盖
    setButton() {

    },
    // 重新 覆盖 按钮事件

    buttonClick({ val }) {
      if (val.buttonCode === 'addAssociated') {
        if (this.selectRow.length > 0) {
          this.formName = 'associatedCustomerSelectPosition';
          this.modalConfig.title = '选择上级';
          this.openModal();
        } else {
          this.$message('请勾选');
        }
      }
    },

    onSubmit(positionArr) {
      console.log(733333, positionArr);
      // 下属-关联上级职位 传（underlingPositionCodeList、positionCode）
      /// mdm/mdmUserUnderlingController/replaceParentPosition
      const params = {
        underlingPositionCodeList: this.selectRow.map((a) => a.underlingPositionCode),
        positionCode: positionArr[0].positionCode,
      };
      request.post('/mdm/mdmUserUnderlingController/replaceParentPosition', params).then((res) => {
        if (res.success) {
          this.selectRow = [];
          this.closeModal();
          this.getList();
          this.$message({
            message: res.message,
            type: 'success',
          });
        }
      });
    },
  },

};
</script>

<style scoped lang="less">

</style>
