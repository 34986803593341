var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "addUser-position-information" },
    [
      _c(
        "el-form",
        {
          ref: "infoObjFormRef",
          attrs: {
            disabled: _vm.formConfig.btnCode === "view",
            model: _vm.infoObj,
            "label-position": "right",
            "label-width": "100px",
          },
        },
        [
          _vm.userCanRelationManyCustomer === "Y"
            ? _c(
                "div",
                _vm._l(_vm.infoObj.customerList, function (item, index) {
                  return _c(
                    "el-row",
                    { key: index },
                    [
                      _c("el-col", { attrs: { span: 24 } }, [
                        _c("div", { staticClass: "arrItemTitle" }, [
                          _c("div", { staticClass: "arrItemTitle-left" }, [
                            _c("p", [
                              _vm._v(" 关联客户" + _vm._s(index + 1) + " "),
                            ]),
                            !(_vm.formConfig.btnCode === "view")
                              ? _c(
                                  "div",
                                  [
                                    index === 0
                                      ? _c(
                                          "el-button",
                                          {
                                            attrs: { type: "text" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.addArrItem(
                                                  _vm.infoObj.userType,
                                                  _vm.infoObj.customerList
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "el-icon-circle-plus-outline",
                                              staticStyle: {
                                                "font-size": "20px",
                                              },
                                            }),
                                          ]
                                        )
                                      : _vm._e(),
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.delArrItem(
                                              _vm.infoObj.userType,
                                              _vm.infoObj.customerList,
                                              index
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "el-icon-delete",
                                          staticStyle: { "font-size": "20px" },
                                        }),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]),
                          _c("div", { staticClass: "arrItemTitle-right" }),
                        ]),
                      ]),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                prop: "customerList." + index + ".customerCode",
                                rules: {
                                  required: true,
                                  message: "关联客户不能为空",
                                  trigger: "change",
                                },
                                label: "关联客户",
                              },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    remote: "",
                                    "remote-method": function (name) {
                                      _vm.getItemCustomerCodeOptionArr(
                                        name,
                                        item.customerCode,
                                        index
                                      )
                                    },
                                    filterable: "",
                                    clearable: "",
                                  },
                                  on: {
                                    focus: function ($event) {
                                      return _vm.getItemCustomerCodeOptionArr(
                                        "",
                                        item.customerCode,
                                        index
                                      )
                                    },
                                  },
                                  model: {
                                    value: item.customerCode,
                                    callback: function ($$v) {
                                      _vm.$set(item, "customerCode", $$v)
                                    },
                                    expression: "item.customerCode",
                                  },
                                },
                                _vm._l(
                                  item.itemCustomerCodeOptionArr,
                                  function (item2, index2) {
                                    return _c("el-option", {
                                      key: index2,
                                      attrs: {
                                        label: item2.label,
                                        value: item2.value,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                1
              )
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }