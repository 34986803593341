<template>
  <div class="home">
    <TablePage :treeCode="treeCode" @resetTrees="reset" ref="table" />
  </div>
</template>

<script>
import TablePage from './table';
// import TablePageTree from '../../../../components/table_page_tree';

export default {
  name: 'Home',
  components: {
    TablePage,
    // TablePageTree,
  },
  created() {},
  computed: {

  },
  methods: {
    treeChange(code) {
      this.treeCode = code;
      this.$refs.table.tablePage.currentPage = 1;
    },
    // 重置
    reset() {
      if (this.treeCode === '') {
        this.$refs.table.getList();
      }
      this.treeCode = '';
      // this.$refs.tableTree.getTreeList('reset');
    },
  },

  data() {
    return {
      treeCode: '',
      treeValue: '',
    };
  },
};
</script>
<style lang="less" scoped>

</style>
