<script>
import TablePage from '../../../../../components/table_page';
import configs from '../data';
import Form from '../form';
import associatedCustomer from '../form/associated_customer/associated_customer.vue';
// 关联设备
import associatedTerminals from '../form/associated_terminals/associated_position.vue';
import lookSubordinates from '../form/look_subordinates/look_subordinates.vue';

import addUser from '../form/add_user/index';

import Modal from '../../../../../components/modal';
// import request from '../../../../../utils/request';

export default {
  extends: TablePage,
  components: {
    Modal,
    Form,
    addUser,
    associatedCustomer,
    associatedTerminals,
    lookSubordinates,
  },
  props: {
    treeCode: String,
  },
  watch: {
    treeCode(val) {
      console.log(val);
      this.params.code = val;
      this.getList();
    },
  },
  data() {
    return {
      requestUrl: '/mdm/mdmCustomerUserController/pageList',
      configs,
      formName: 'Form',
      formConfig: {},
      modalConfig: {
        title: '新增',
        visible: false,
        type: '',
      },
    };
  },
  created() {
    this.getTableConfig();
  },
  methods: {
    // 获取表格配置信息
    getTableConfig() {
      this.getConfigList('mdm_user_client_management');
      // this.getConfigList('mdm_user_management');
    },
    beforeSearchEvent(val) {
      if (val.$event.type === 'reset') {
        this.$emit('reset');
      }
      return true;
    },
    beforeModalClick({ val }) {
      if (val.code === 'enable' || val.code === 'disable' || val.code === 'delete') {
        this.resetTree();
        return false;
      }
      return true;
    },
    modalClick({ val, row }) {
      if (!this.beforeModalClick({ val, row })) return;
      this.formConfig = {};
      if (val.code === 'add') {
        this.formName = 'addUser';
        this.formConfig = {};
        this.modalConfig.title = '新增';
        this.openFull();
      }
      if (val.code === 'edit') {
        this.formName = 'addUser';
        this.formConfig = row;
        this.modalConfig.title = '编辑';
        this.openFull();
      }
      // 查看
      if (val.code === 'view') {
        this.formName = 'addUser';
        this.formConfig = { ...row, ...val, btnCode: 'view' };
        this.modalConfig.title = '查看';
        this.openFull();
      }
      if (val.buttonName === '关联客户') {
        this.formConfig = row;
        this.formName = 'associatedCustomer';
        this.modalConfig.title = '关联客户';
        this.openFull();
      }
      if (val.code === 'associated_terminal') {
        this.formConfig = row;
        this.formName = 'associatedTerminals';
        this.modalConfig.title = '关联终端';
        this.openFull();
      }
      if (val.code === 'subordinates') {
        this.propsObjInData = row;
        this.formName = 'lookSubordinates';
        this.modalConfig.title = '查看下属';
        this.openModal();
      }
      if (val.code === 'reset_password') {
        if (this.selectRow.length > 0) {
          this.formConfig = { btnCode: 'reset_passwords', selectRow: this.selectRow };
          this.formName = 'Form';
          this.modalConfig.title = '重置密码';
          this.modalConfig.width = '600px';
          this.modalConfig.height = '200px';
          this.openModal();
        } else {
          this.$message('最少选择一条数据操作');
        }
      }
      // console.log(row);
      console.log(6333333, val);
    },
  },
};
</script>
